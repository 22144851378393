var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-chart-pie-36',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Student Athlete Transactions',
            path: '/dashboard',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'General Funding',
            path: '/general-funding-dashboard',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Donation Funding',
            path: '/donation-funding-dashboard',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Schools',
          icon: 'fa fa-university',
        }}},[_c('div',{staticClass:"search-bar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"search-input-nav",attrs:{"type":"text","placeholder":"Search schools"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}})]),_vm._l((_vm.filteredSchools),function(item){return _c('sidebar-item',{key:item.id,attrs:{"link":{
            name: item.name,
            path: `/orginization/${item.abbreviation}`,
          }}})})],2)],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }