/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.4.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";
// Store Setup
import store from "./store/index";
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueResource);
Vue.use(FontAwesomeIcon);

library.add(faPhone);

// Filter Settings
Vue.filter("currency", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

// Http settings
Vue.config.productionTip = false;
// Vue.http.options.root = "http://127.0.0.1:8000";
Vue.http.options.root = process.env.VUE_APP_API_BASE_URL;
Vue.http.interceptors.push((request, next) => {
  const accessToken = localStorage.getItem("api_token");
  const csrfToken = document.querySelector('meta[name="csrf-token"]');

  request.headers.set("X-CSRF-TOKEN", csrfToken);
  request.headers.set("Authorization", `Bearer ${accessToken}`);
  request.headers.set("Access-Control-Allow-Origin", "*");

  next((res) => {
    if (res.status === 401) {
      store
        .dispatch("logout")
        .then(() => {
          router.push("/login");
        })
        .catch((err) => router.push("/login"));
    }
  });
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  i18n,
  library,
});
