<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="https://www.blueprintsports.com/">
            Blue Print Sports
          </a>
        </li>
      </ul>
      <div class="copyright">
        © {{ year }} a product of
        <a
          href="https://www.blueprintsports.com/"
          target="_blank"
          rel="noopener"
          >Blue Print</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
