<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Student Athlete Transactions',
              path: '/dashboard',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'General Funding',
              path: '/general-funding-dashboard',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Donation Funding',
              path: '/donation-funding-dashboard',
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Schools',
            icon: 'fa fa-university',
          }"
        >
          <div class="search-bar">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search schools"
              class="search-input-nav"
            />
          </div>
          <sidebar-item
            v-for="item in filteredSchools"
            :key="item.id"
            :link="{
              name: item.name,
              path: `/orginization/${item.abbreviation}`,
            }"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath"></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// import SidebarShare from "./SidebarSharePlugin";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition,
    // SidebarShare,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
      schools: [],
      searchQuery: "",
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    // Need to ensure, only schools the user has access to are shown
    initSchools() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URL}/api/user`)
        .then((res) => {
          this.schools = res.data.schools;
        })
        .catch((err) => console.log(err));
    },
  },
  async mounted() {
    await this.initSchools();

    this.initScrollbar();
  },
  computed: {
    filteredSchools() {
      return this.schools.filter((school) =>
        school.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
.search-bar {
  text-align: center;
}
/* Optional: style for the search input */
.search-input-nav {
  margin-bottom: 10px;
  padding: 5px;
  width: 70%;
  border: none;
  background-color: rgb(34, 90, 244);
  border-radius: 0.4285rem;
  color: white;
}

.search-input-nav::placeholder {
  color: white;
}
</style>
