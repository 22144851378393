import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isAuthenticated: localStorage.getItem("is_auth") || false,
    apiToken: localStorage.getItem("api_token") || "",
    schools: localStorage.getItem("schools") || [],
    isAdmin: localStorage.getItem("isAdmin") || false,
  },
  mutations: {
    setAuth(state, payload) {
      // Store the token in local storeage
      console.log(payload.schools);
      const schools = payload.schools.map((obj) => obj.abbreviation).join(", ");
      localStorage.setItem("api_token", payload.apiToken);
      localStorage.setItem("is_auth", payload.isAuth);
      localStorage.setItem("schools", schools);
      localStorage.setItem("isAdmin", payload.isAdmin);
      // Set the states
      state.isAuthenticated = payload.isAuth;
      state.apiToken = payload.apiToken;
    },
  },
  actions: {
    login({ commit }, authObject) {
      // Perform login logic, then:
      const { authToken, schools, isAdmin } = authObject;
      const payload = {
        isAuth: true,
        schools: schools,
        isAdmin: isAdmin,
        apiToken: authToken,
      };
      commit("setAuth", payload);
    },
    logout({ commit }) {
      this.state.isAuthenticated = false;
      this.state.apiToken = "";
      localStorage.removeItem("is_auth");
      localStorage.removeItem("api_token");
      localStorage.removeItem("schools");
      localStorage.removeItem("isAdmin");
    },
  },
  getters: {
    isAuthenticated(state) {
      if (state.isAuthenticated && state.apiToken != "") {
        return true;
      }
      return false;
    },
    authToken(state) {
      return state.apiToken;
    },
  },
});

export default store;
