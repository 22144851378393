import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  // console.log(store.getters.isAuthenticated);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      // next();
      const school = to.path.replace("/orginization/", "");
      if (to.path.includes("/orginization")) {
        if (store.state.isAdmin) {
          next();
        } else if (!store.state.schools.includes(school)) {
          console.log("no school");
          router.push("/dashboard");
        }
      } else if (from.path == "/login") {
        next();
      } else if (store.state.isAdmin) {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
